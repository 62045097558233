.loading {
    width: 100%;
    position: relative;

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__children {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        &__load {
            opacity: 0.6;
            pointer-events: none;
            height: 100%;
            flex-grow: 1;
        }
        &__loadCustome {
            opacity: 0.4;
            pointer-events: none;
            height: 100%;
            background-color: rgba(#eee, 0.1);
            background-image: linear-gradient(
                -45deg,
                rgba(255, 255, 255, 1) 25%,
                transparent 25%,
                transparent 50%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 1) 75%,
                transparent 75%,
                transparent
            );

            background-size: 30px 30px;
            animation: move 2s linear infinite;
            transition: width 2s ease-out;
        }
    }
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 30px 30px;
    }
}
